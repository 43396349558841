import { createContext } from "preact";
import { useState, useEffect } from "preact/hooks";

export const AuthContext = createContext();

const auth_initialState = {};

const AuthProvider = (props) => {
  const [authState, setauthState] = useState(auth_initialState);

  const asyncfirebase = async ({ name }) => {
    const asyncfirebaseModules = await import("../components/auth/Firebase");
    name === "hydrateAuth" &&
      asyncfirebaseModules.hydrateAuth(authState, setauthState);
  };

  //Load fonts with auth
  useEffect(() => {
    props.heroLoaded &&
      asyncfirebase({ name: "hydrateAuth" }) &&
      document.body.classList.add("loadFonts");
  }, [props.heroLoaded]);

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { Fragment } from "preact";
import Router from "preact-router";
//Remember to take this line out on production builds!
//import "preact/debug";
import AuthProvider from "./contexts/AuthContext";
import Home from "./routes/home";
import { hasWindow, useMediaQuery } from "./utils";
import { useEffect, useState } from "preact/hooks";

//Preloading these styles to prevent additional css chunk requests (lighthouse optimising)
import GridStyles from "./routes/home/grid_view/Grid_Layout.module.scss";
import ThumbStyles from "./routes/home/grid_view/Thumbnail/Thumbnail.module.scss";
import VideoPopoutStyles from "./components/popouts/VideoPopout.module.scss";
import PlayerStyles from "./components/player/Player.module.scss";
import GeneralPopoutStyles from "./components/popouts/GeneralPopout.module.scss";
import SignInFormStyles from "./components/auth/SignInForm.module.scss";

//Preloader to improve image load speed (only preloading for non mobile)
import { PreloadImages } from "./routes/home/grid_view/Grid_Content";
import MediaProvider from "./contexts/MediaContext";

const App = () => {
  const [heroLoaded, setheroLoaded] = useState(false);

  hasWindow &&
    window.innerWidth > 400 &&
    useEffect(() => {
      PreloadImages();
    }, []);

  return (
    <div id="app">
      <AuthProvider heroLoaded={heroLoaded}>
        <MediaProvider>
          <Router>
            <Home
              setheroLoaded={setheroLoaded}
              GridStyles={GridStyles}
              ThumbStyles={ThumbStyles}
              PlayerStyles={PlayerStyles}
              VideoPopoutStyles={VideoPopoutStyles}
              GeneralPopoutStyles={GeneralPopoutStyles}
              SignInFormStyles={SignInFormStyles}
              exact
              path="/"
            />
          </Router>
        </MediaProvider>
      </AuthProvider>
    </div>
  );
};

export default App;

import { createContext } from "preact";
import { useState, useEffect } from "preact/hooks";
import { hasWindow } from "../utils";

export const MediaContext = createContext();

// Sets a listener that updates state eg. {media: "for-mobile-only"}
const MediaProvider = (props) => {
  const [mediaCheck, setmediaCheck] = useState(null);

  const setMediaFunctionHandler = (newMedia) => {
    // console.log("newmedia", newMedia);

    setmediaCheck((prevState) => {
      return {
        ...prevState,
        ...newMedia,
      };
    });
  };

  // useEffect(() => {
  //   console.log("media check ", mediaCheck);
  // }, [mediaCheck]);

  if (hasWindow) {
    useEffect(() => {
      //List of media queries mapped to names
      const mediaList = [
        { "for-mobile-only": "(max-width: 767px)" },
        { "for-tablet-up": "(min-width: 768px)" },
        { "for-laptop-up": "(min-width: 1200px)" },
        { "for-pc-up": "(min-width: 1600px)" },
        { "for-mobile-and-tablet": "(max-width: 1599px)" },
      ];

      //Create a new media object that you can query eg. media["for-pc-up"]
      const media = Object.assign(
        {},
        ...mediaList.map((item) => ({
          [Object.keys(item)[0]]: window.matchMedia(Object.values(item)[0]),
        }))
      );

      setmediaCheck(media);

      //Setup listeners for changes in media and update state
      Object.values(media).forEach((query) => {
        query.addListener(() => setMediaFunctionHandler(media));
      });
    }, []);
  } else {
    return false;
  }

  return (
    <MediaContext.Provider value={mediaCheck}>
      {props.children}
    </MediaContext.Provider>
  );
};

export default MediaProvider;

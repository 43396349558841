import { lazy } from "preact/compat";
import { useEffect, useState } from "preact/hooks";

// Joins CSS style imports together to add to className
export const stylesJoin = (...params) => params.join(" ");

// check if on node or browser for SSR
export const hasWindow = typeof window !== "undefined";

//Lazy-load preloader
export const ReactLazyPreload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};


import eearth_jpg from "./../../../assets/images/eearth.jpg";
import eearth_webp from "./../../../assets/images/webp/eearth.webp";
import eearth_400_webp from "./../../../assets/images/webp/eearth_400.webp";
import card_jpg from "./../../../assets/images/card.jpg";
// import card_webp from "./../../../assets/images/webp/card.webp";
import card_400_webp from "./../../../assets/images/webp/card_400.webp";
import doom_jpg from "./../../../assets/images/doom.jpg";
// import doom_webp from "./../../../assets/images/webp/doom.webp";
import doom_400_webp from "./../../../assets/images/webp/doom_400.webp";
import blender_jpg from "./../../../assets/images/blender.jpg";
// import blender_webp from "./../../../assets/images/webp/blender.webp";
import blender_400_webp from "./../../../assets/images/webp/blender_400.webp";
import engine_jpg from "./../../../assets/images/engine.jpg";
// import engine_webp from "./../../../assets/images/webp/engine.webp";
import engine_400_webp from "./../../../assets/images/webp/engine_400.webp";
import fight_jpg from "./../../../assets/images/fight.jpg";
// import fight_webp from "./../../../assets/images/webp/fight.webp";
import fight_400_webp from "./../../../assets/images/webp/fight_400.webp";
import hiro_jpg from "./../../../assets/images/hiro.jpg";
// import hiro_webp from "./../../../assets/images/webp/hiro.webp";
import hiro_400_webp from "./../../../assets/images/webp/hiro_400.webp";
import ocean_metals_jpg from "./../../../assets/images/ocean_metals.jpg";
// import ocean_metals_webp from "./../../../assets/images/webp/ocean_metals.webp";
import ocean_metals_400_webp from "./../../../assets/images/webp/ocean_metals_400.webp";
import science_jpg from "./../../../assets/images/science.jpg";
// import science_webp from "./../../../assets/images/webp/science.webp";
import science_400_webp from "./../../../assets/images/webp/science_400.webp";
import thiss_jpg from "./../../../assets/images/thiss.jpg";
import thiss_webp from "./../../../assets/images/webp/thiss.webp";
import thiss_400_webp from "./../../../assets/images/webp/thiss_400.webp";

const science_webp = "./../../../assets/images/pinky.jpg"
const hiro_webp = "./../../../assets/images/ironing.jpg"
const fight_webp = "./../../../assets/images/boat.jpg"
const doom_webp = "./../../../assets/images/sex.jpg"
const ocean_metals_webp = "./../../../assets/images/painter.jpg"
const engine_webp = "./../../../assets/images/cater.jpg"
const blender_webp = "./../../../assets/images/waaa.jpg"
const card_webp = "./../../../assets/images/pinky.jpg"


// prettier-ignore
export const thumbnailsMap = new Map([
  // ["", { small: eearth_400_webp, large: eearth_webp, jpg: eearth_jpg }],
  ["Mark Pritchard", { small: card_400_webp, large: card_webp, jpg: card_jpg }],
  ["Steven Hales", { small: doom_400_webp, large: doom_webp, jpg: doom_jpg }],
  ["Amy Frier", { small: engine_400_webp, large: engine_webp, jpg: engine_jpg }],
  // ["Phillip Martin", { small: blender_400_webp, large: blender_webp, jpg: blender_jpg }],

  ["Ranjit Sing", { small: fight_400_webp, large: fight_webp, jpg: fight_jpg }],
  ["Francis Saunders", { small: ocean_metals_400_webp, large: ocean_metals_webp, jpg: ocean_metals_jpg}],
  ["Marco Felix", { small: hiro_400_webp, large: hiro_webp, jpg: hiro_jpg }],

  ["Hiro Nakamora", { small: science_400_webp, large: science_webp, jpg: science_jpg }],
  ["Davide Haslof",{ small: doom_400_webp, large: doom_webp, jpg: doom_jpg }],
  // ["Milo Kaleeni", { small: eearth_400_webp, large: eearth_webp, jpg: eearth_jpg }],
  ["Amy Frier", { small: engine_400_webp, large: engine_webp, jpg: engine_jpg }],
  ["Phillip Martin", { small: blender_400_webp, large: blender_webp, jpg: blender_jpg }],
  ["Magnus Carlson", { small: card_400_webp, large: card_webp, jpg: card_jpg }],
  ["Beth Speed", { small: doom_400_webp, large: doom_webp, jpg: doom_jpg }],
  ["Kyle Struthers", { small: blender_400_webp, large: blender_webp, jpg: blender_jpg }],
  ["Stephen Dennis", { small: engine_400_webp, large: engine_webp, jpg: engine_jpg }],
  ["Paul Watson", { small: fight_400_webp, large: fight_webp, jpg: fight_jpg }],
  ["Denise Outon", { small: hiro_400_webp, large: hiro_webp, jpg: hiro_jpg }],
  ["Lance Armstrong", { small: ocean_metals_400_webp, large: ocean_metals_webp, jpg: ocean_metals_jpg}],
  ["Felis Magadan", { small: science_400_webp, large: science_webp, jpg: science_jpg }],
  // ["Piere White", { small: thiss_400_webp, large: thiss_webp, jpg: thiss_jpg }],
]);

const preloader = (url) => {
  var img = new Image();
  img.src = url;
};

export const PreloadImages = () => {
  if (window.innerWidth < 401) {
    preloader(eearth_400_webp);
    preloader(card_400_webp);
  } else {
    thumbnailsMap.forEach(function (value, key) {
      preloader(value.large);
    });
  }

  return;
};
